<template>
  <div class="topaff-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="9" sm="12">
            <h1>{{ $t('TOP_AFFILIATE_PAGE_TITLE') }}</h1>
          </CCol>
          <CCol md="3" sm="12"> </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody v-if="!isLoading">
        <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
          <template ##="{ item, index }">
            <td>{{ index + 1 }}</td>
          </template>
          <template #action="{ item }">
            <td>
              <button v-if="isAllowedViewRefferalStructure" class="edit-member-btn" @click="viewTreeChart(item.id)">
                <i class="fas fa-tree"></i>
              </button>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { Constants } from '@/constants';

export default {
  name: 'TopAffiliateList',
  components: {},
  data() {
    return {
      items: [],
      isAllowedView: false,
      dateFormat: Constants.dateFormat,
      timestamp: new Date(),
      isLoading: true,
      isAllowedViewRefferalStructure: false,
      fields: [
          {
          key: '#',
          label: '#',
          _style: 'width: 40px',
        },
        {
          key: 'email',
          label: this.$t('TOP_AFFILIATE_PAGE_TABLE_COL_EMAIL'),
        },
        {
          key: 'referral_code',
          label: this.$t('TOP_AFFILIATE_PAGE_TABLE_COL_REFERRAL_CODE'),
        },
        {
          key: 'num_of_refers',
          label: this.$t('TOP_AFFILIATE_PAGE_TABLE_COL_COUNT'),
        },
        {
          key: 'action',
          label: '',
          _style: 'min-width: 100px',
        },
      ],
      errorMessage: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    await this.getTopAffiliate();
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'VIEW_TOP_AFFILIATE') {
            this.isAllowedView = true;
          }
          if (item.name === 'MEMBERSHIP_VIEW_REFERRAL_STRUCTURE') {
            this.isAllowedViewRefferalStructure = true;
          }
        });
      }
    },
    async getTopAffiliate() {
      try {
        const result = await this.$http.get(endpoints.getTopAffiliate);
        this.items = result.data.items || [];
        this.errorMessage = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('TOP_AFFILIATE_LIST_PAGE_NOTIFY_TITLE_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    viewTreeChart(memberId) {
      this.$router.push(`../members/${memberId}/tree-chart`);
    },
  },
};
</script>
<style lang="scss">
.topaff-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .checkbox-section {
    .selected-all-checkbox {
      width: 40px;
      text-align: center;
    }

    .btn-update-status {
      width: 111px;
      border: 1px solid #1a2958;
      border-radius: 15px;
      background-color: #ffffff;
      color: #4d5666;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }
  .selected-claim-request-list-table-card {
    margin-bottom: 0;
  }
  .card {
    .notification {
      color: red;
      margin: 5px 0 20px 0;
      font-size: 13px;
    }
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
      .btn-upload-csv {
        border: none;
        border-radius: 32px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        border: 1px solid #1a2958;
        border-radius: 17.5px;
        background-color: #1a2958;
        width: 100%;
        color: #5da30b;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        border: 1px solid #5da30b;
        border-radius: 16px;
        background-color: #ffffff;
      }
    }

  }
   .edit-member-btn {
    font-size: 14px;
    font-weight: 400;
    color: #2b7ef1;
    outline: none;
    svg {
      width: 22px;
      height: 22px;
      font-size: 22px;
    }
  }
  .edit-member-btn {
    background-color: unset;
    border: none;
    outline: none;
}
}

</style>
